import React from "react"

export default function Footer(props) {
  const { author, year } = props

  return (
    <footer className="footer mt-auto py-3 bg-light">
      <div className="container text-center">
        <span className="text-muted">
          &copy; {author} {year}
        </span>
      </div>
    </footer>
  )
}
