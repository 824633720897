import React from "react"
import { Link } from "gatsby"
import Logo from "../static/filippo-bovo-logo.svg"

export default function Header(props) {
  const { title } = props

  return (
    <div className="container">
      <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <Link
          to="/"
          className="d-flex align-items-center mb-1 me-auto text-dark text-decoration-none"
        >
          <Logo width="32px" height="32px" className="bi me-3" />
          <span className="h2 my-0">{title}</span>
        </Link>

        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link to="/" className="nav-link text-dark">
              Home
            </Link>
          </li>
        </ul>
      </header>
    </div>
  )
}
