import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { title } = data.site.siteMetadata
  const currentYear = new Date().getFullYear()

  return (
    <div className="d-flex flex-column h-100">
      <Header title={title} />
      <main className="flex-shrink-0">{children}</main>
      <Footer author={title} year={currentYear} />
    </div>
  )
}
